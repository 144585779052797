body {
  height:100%;
  overflow: hidden;
}
.topheader {
  padding:20px 20px 0 30px;
  display:flex;
  flex-direction: column;
}

.header {
  padding: 10px 0;
}
.App_wrapper{
  display:flex;
  flex-direction: row;
  margin: 20px;
  padding: 20px;
  border : 2px solid #eeeeee;
  border-radius: 8px;
}
.subtitle_wrap {
  margin-left: 20px;
  width: 100%;
  padding: 0 20px;
  height:100vh;
  overflow-y: auto;
}
.subtitle_default {
  height: calc(100vh - 120px);

  display: flex;
  align-items: center;
  justify-content: center;
}

.NullvideoArea {
  width: 640px;
  height: 390px;
  background-color: #000000;
}

.ad {
  position: fixed;
  bottom:0;
  left:30px;
}

.subtitleArea:focus-within {
  border: 2px solid rgb(0,115,240) !important;

}
